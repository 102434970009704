import * as React from 'react';

import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Seo from '../components/seo';
import Layout from '../components/layout';

// import ErrorImage from '../images/404-image.png';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="mt-44 mb-20 text-center font-futura">
      <StaticImage
        className="mr-auto ml-auto"
        src="../images/404-image.png"
        alt="404 error"
        placeholder="blurred"
        width={1000}
        height={400}
      />
      <h1>Page Not Found</h1>
      <p>Sorry, we can&apos;t find that page</p>
      <Link to="/" className="no-underline text-lg text-black hover:no-underline" rel="preload">Go Home</Link>
    </div>
  </Layout>
);

export default NotFoundPage;
